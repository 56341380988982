import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";
import Logo from "./asset/logo/logo.jpg";

class App extends Component {
  state = { isAuthenticated: false, isAuthenticating: true };

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">报名首页</Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                {this.state.isAuthenticated ? (
                  <Fragment>
                    <LinkContainer to="/settings">
                      <NavItem>设置</NavItem>
                    </LinkContainer>
                    <NavItem onClick={this.handleLogout}>退出</NavItem>
                  </Fragment>
                ) : (
                  <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem>注册</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>登录</NavItem>
                    </LinkContainer>
                  </Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="branding-container">
            <div className="site-branding">
              <a href="https://coremusic.net/">
                <img className="custom-logo" src={Logo} alt="coremusic_logo" />
              </a>
            </div>
          </div>
          <Routes childProps={childProps} />
        </div>
      )
    );
  }
}

export default withRouter(App);
