import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const AsyncHome = asyncComponent(() => import("./containers/Home"));
const AsyncLogin = asyncComponent(() => import("./containers/Login"));
const AsyncRegistrations = asyncComponent(() =>
  import("./containers/Registrations")
);
const AsyncSignup = asyncComponent(() => import("./containers/Signup"));
const AsyncNewRegistration = asyncComponent(() =>
  import("./containers/NewRegistration")
);
const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));
const AsyncResetPassword = asyncComponent(() =>
  import("./containers/ResetPassword")
);
const AsyncSettings = asyncComponent(() => import("./containers/Settings"));
const AsyncChangePassword = asyncComponent(() =>
  import("./containers/ChangePassword")
);
const AsyncReview = asyncComponent(() => import("./containers/Review"));
const AsyncApplications = asyncComponent(() =>
  import("./containers/Applications")
);
const AsyncAdminEdit = asyncComponent(() => import("./containers/AdminEdit"));

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={AsyncHome} props={childProps} />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={AsyncLogin}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signup"
      exact
      component={AsyncSignup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login/reset"
      exact
      component={AsyncResetPassword}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/registrations/new"
      exact
      component={AsyncNewRegistration}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/review"
      exact
      component={AsyncReview}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/applications/:uid/:rid"
      exact
      component={AsyncApplications}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/edit/:uid/:rid"
      exact
      component={AsyncAdminEdit}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/registrations/:id"
      exact
      component={AsyncRegistrations}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings"
      exact
      component={AsyncSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings/password"
      exact
      component={AsyncChangePassword}
      props={childProps}
    />
    {/* Finally, catch all unmatched routes */}
    <Route component={AsyncNotFound} />
  </Switch>
);
