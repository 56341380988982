export default {
  MAX_ATTACHMENT_SIZE: 100000000,
  s3: {
    REGION: "ap-northeast-2",
    BUCKET: "audition-asia"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://rr9xtxqfa5.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_6p60kxl37",
    APP_CLIENT_ID: "77dauhg75gjvnmfbmgipeg631d",
    IDENTITY_POOL_ID: "us-east-1:0a168ed1-dc39-44b5-8a63-8bbb84fe660f"
  }
};
